import Radio, { radioClasses } from '@mui/material/Radio';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
const BlueRadio = styled(Radio)({
    color: blue[400],
    [`& ${radioClasses.checked}`]: {
        color: blue[600],
    },
});
export default BlueRadio;
