import { jsx as _jsx } from "react/jsx-runtime";
import { useSetIsAllChecked, useSetDeletableRowData } from '_shared/hooks';
import TableCheckbox from 'components/basic/table-checkbox';
import { useAppSelector } from 'store/hooks';
import { selectDeletableRowData, selectIsAllDeleteChecked } from 'store/reducers/ui/multi-delete/selectors';
function AllCheck({ data }) {
    const handleAllDeleteCheck = useSetIsAllChecked();
    const handleSetRowData = useSetDeletableRowData();
    const isAllDeleteChecked = useAppSelector(selectIsAllDeleteChecked);
    const deletableRowData = useAppSelector(selectDeletableRowData);
    return (_jsx(TableCheckbox, { checked: isAllDeleteChecked, indeterminate: !isAllDeleteChecked && deletableRowData.length > 0, disabled: data.filter((i) => i.IsDeletable).length === 0, onChange: (event) => {
            handleAllDeleteCheck(event.target.checked);
            if (event.target.checked) {
                handleSetRowData(data.filter((item) => item.IsDeletable).map((item) => item.Id));
            }
            else {
                handleSetRowData([]);
            }
        } }));
}
export default AllCheck;
