import { jsx as _jsx } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import * as styles from './select.module.scss';
const IxSelect = ({ children, rootClassName, ...other }) => {
    return (_jsx(TextField, { FormHelperTextProps: {
            classes: {
                root: styles.colorRed,
            },
        }, select: true, classes: {
            root: styles.inputRoot,
        }, SelectProps: {
            native: true,
            classes: {
                select: clsx([styles.selectPadding, { [rootClassName]: !!rootClassName }]),
            },
        }, variant: 'outlined', fullWidth: true, ...other, children: children }));
};
export default IxSelect;
