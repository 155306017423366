import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { setWithExpiry, getWithExpiry } from './storage';
const ErrorFallback = (props) => {
    const { error } = props;
    useEffect(() => {
        const chunkFailedMessage = /Loading chunk [\w_.]+ failed/i;
        if (error?.message && chunkFailedMessage.test(error.message)) {
            if (!getWithExpiry('chunk_failed')) {
                setWithExpiry('chunk_failed', 'true', 10000);
                window.location.reload();
            }
        }
    }, [error]);
    return (_jsxs("div", { role: 'alert', children: [_jsx("p", { children: "Something went wrong:" }), _jsx("pre", { style: { color: 'red' }, children: error.message }), _jsx("pre", { style: { color: 'red' }, children: error.stack })] }));
};
export default ErrorFallback;
