import { jsx as _jsx } from "react/jsx-runtime";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useHideToast } from './hooks';
import * as styles from './snackbar.module.scss';
const IxSnackBar = ({ open, severity, message }) => {
    const hideToast = useHideToast();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        hideToast();
    };
    return (_jsx(Snackbar, { open: open, autoHideDuration: 2000, onClose: handleClose, anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        }, disableWindowBlurListener: true, children: _jsx(Alert, { onClose: handleClose, severity: severity, elevation: 6, variant: 'filled', classes: {
                filledSuccess: styles.filledSuccess,
            }, children: message }) }));
};
export default IxSnackBar;
