import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import NoDataMessage from 'components/NoDataMessage';
import IxTooltip from 'components/basic/ix-tooltip';
import { useAppSelector } from 'store/hooks';
import { selectDeletableRowData } from 'store/reducers/ui/multi-delete/selectors';
import Language from 'utils/language';
import CommonCheckbox from '../_shared/common-checkbox';
import EnhancedTableHeader from './header/enhanced-table-header';
import * as styles from './list.module.scss';
function Index(props) {
    const { columns, defaultSortedColumn, hasCheckBox, data, onDeleteHandler, onEditHandler, order: userOrder, rowOnClickHandler, customRowClass = undefined, getSortColumnOrder, shouldClientSideSort, } = props;
    const deletableRowData = useAppSelector(selectDeletableRowData);
    const [order, setOrder] = useState(userOrder || 'asc');
    const [orderBy, setOrderBy] = useState(defaultSortedColumn);
    const [selectedLanguage] = useState(Language.jap);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    useEffect(() => {
        if (typeof getSortColumnOrder === 'function') {
            getSortColumnOrder(orderBy, order);
        }
    }, [getSortColumnOrder, order, orderBy]);
    const orderedData = useMemo(() => {
        return shouldClientSideSort ? _.orderBy(data, (value) => value[orderBy] || '', [order]) : data;
    }, [data, order, orderBy, shouldClientSideSort]);
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { id: 'ix_data_table', stickyHeader: true, className: clsx([styles.root, { [styles.checkBoxPadding]: hasCheckBox }]), children: [_jsx(EnhancedTableHeader, { data: data, columns: columns, order: order, orderBy: orderBy, onRequestSort: handleRequestSort }), _jsx(_Fragment, { children: data.length > 0 ? (_jsx(TableBody, { children: orderedData.map((item) => {
                                let rowHandlerReturn = undefined;
                                if (typeof rowOnClickHandler === 'function') {
                                    rowHandlerReturn = rowOnClickHandler(item);
                                }
                                else {
                                    rowHandlerReturn = undefined;
                                }
                                return (_jsx(TableRow, { className: clsx([
                                        { [styles.selectableRow]: typeof rowHandlerReturn === 'function' },
                                        { [styles.tableRowSelected]: deletableRowData.includes(item.Id) },
                                        ...[customRowClass ? customRowClass(item) : ''],
                                    ]), onClick: () => {
                                        if (typeof rowHandlerReturn === 'function') {
                                            rowHandlerReturn();
                                        }
                                    }, children: columns.map((column) => (_jsx(React.Fragment, { children: column.id === 'Checkbox' ? (_jsx(TableCell, { component: 'td', children: _jsx(CommonCheckbox, { item: item, column: column }) })) : column.id === 'Action' ? (column.actionType === 'default' ? (_jsx(TableCell, { align: 'left', variant: 'body', component: 'td', children: _jsxs("div", { className: styles.editDeleteBtnContainer, children: [_jsx(IxTooltip, { padding: 10, title: _jsx(Typography, { className: styles.editDeleteTooltipStyle, children: selectedLanguage.EditIconTitle }), children: _jsx("div", { style: {
                                                                marginRight: 10,
                                                            }, onClick: () => {
                                                                if (onEditHandler) {
                                                                    onEditHandler(item);
                                                                }
                                                            }, children: _jsx(CreateIcon, { fontSize: 'small', className: styles.editDeleteIconsStyle }) }) }), 'IsDeletable' in item && item.IsDeletable ? (_jsx(IxTooltip, { padding: 10, title: _jsx(Typography, { className: styles.editDeleteTooltipStyle, children: selectedLanguage.DeleteIconTitle }), children: _jsx("div", { onClick: () => {
                                                                if (onDeleteHandler) {
                                                                    onDeleteHandler(item);
                                                                }
                                                            }, children: _jsx(DeleteOutlineIcon, { fontSize: 'small', className: styles.editDeleteIconsStyle }) }) })) : null] }) })) : (_jsx(TableCell, { align: 'left', variant: 'body', component: 'td', children: column.customActions ? column.customActions(item) : null }))) : (_jsx(TableCell, { className: column.type === 'string' ? 'text-ellipsis' : undefined, align: 'left', variant: 'body', component: 'td', children: column.render ? column.render(item) : null })) }, column.id))) }, item.Id));
                            }) })) : null })] }), data.length === 0 ? _jsx(NoDataMessage, {}) : null] }));
}
export default Index;
