import { jsx as _jsx } from "react/jsx-runtime";
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';
import Language from 'utils/language';
import { useScreenSizeContext } from '../utility/screen-size-context';
import * as styles from './form-fields.module.scss';
import 'moment/locale/ja';
const DateInput = (props) => {
    const { meta: { submitting, error, invalid }, input: { value, onBlur, ...inputProps }, ...others } = props;
    const [selectedLanguage] = useState(Language.jap);
    const { isMobile } = useScreenSizeContext();
    const onChange = (date) => {
        Date.parse(date) ? inputProps.onChange(new Date(date).toUTCString()) : inputProps.onChange(null);
    };
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterMoment, localeText: {
            cancelButtonLabel: selectedLanguage.BtnCancel,
            okButtonLabel: '確定',
        }, children: _jsx(DateTimePicker, { ...inputProps, ...others, slotProps: {
                textField: {
                    helperText: error,
                    error: invalid,
                    fullWidth: true,
                    placeholder: value ? undefined : '',
                    InputProps: {
                        classes: {
                            input: styles.outlinedInput,
                        },
                    },
                },
                desktopPaper: {
                    classes: {
                        root: clsx([styles.dateFieldPaper, styles.paperStyle]),
                    },
                },
                mobilePaper: {
                    classes: {
                        root: clsx([styles.dateFieldPaper, styles.paperStyle]),
                    },
                },
                dialog: {
                    PaperProps: {
                        className: styles.dateFieldPaper,
                    },
                },
                toolbar: {
                    hidden: true,
                },
                tabs: {
                    hidden: !isMobile,
                },
                popper: {
                    placement: 'auto',
                },
            }, format: 'YYYY-MM-DD hh:mm A', value: value ? moment(value) : null, disabled: submitting, onChange: onChange, ampm: true, ampmInClock: true, views: ['day', 'hours', 'minutes'], openTo: isMobile ? 'day' : 'hours', viewRenderers: {
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            } }) }));
};
export default DateInput;
