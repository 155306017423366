// extracted by mini-css-extract-plugin
var _1 = "_colorCodeDeepBlueBold_NVJPL";
var _2 = "_colorCodeGrey_jaHLo";
var _3 = "_colorGreen_F9gK7";
var _4 = "_colorRed_ndYza";
var _5 = "_downArrow_hZ3QM";
var _6 = "_flex-justify-100p_fYsHB";
var _7 = "_fontColorBlack_QPdsL";
var _8 = "_fontColorCodeDarkBlue_hOohk";
var _9 = "_fontColorCodeDarkGray_rcNmN";
var _a = "_fontColorCodeLightGreen_q3k40";
var _b = "_fontColorCodeYellow_ZFqw9";
var _c = "_fontColorLight_8wXBR";
var _d = "_graphImagePreview_g57Iv";
var _e = "#1fa8a0";
var _f = "_imageSmall_DRH5y";
var _10 = "_padding-bottom-10_Iaz0W";
var _11 = "_padding-top-10_Dwok5";
var _12 = "_primaryColorCode_r4ieX";
var _13 = "_primaryDarkColor_nSDy6";
var _14 = "_profilePopUp_y3jjO";
var _15 = "#f44336";
var _16 = "_safeState_XQVw8";
var _17 = "_userNameTitle_aJghe";
var _18 = "_valueBold_r69GF";
export { _1 as "colorCodeDeepBlueBold", _2 as "colorCodeGrey", _3 as "colorGreen", _4 as "colorRed", _5 as "downArrow", _6 as "flex-justify-100p", _7 as "fontColorBlack", _8 as "fontColorCodeDarkBlue", _9 as "fontColorCodeDarkGray", _a as "fontColorCodeLightGreen", _b as "fontColorCodeYellow", _c as "fontColorLight", _d as "graphImagePreview", _e as "greenColor", _f as "imageSmall", _10 as "padding-bottom-10", _11 as "padding-top-10", _12 as "primaryColorCode", _13 as "primaryDarkColor", _14 as "profilePopUp", _15 as "redColor", _16 as "safeState", _17 as "userNameTitle", _18 as "valueBold" }
