import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useUnwrapAsyncThunk } from 'store/hooks';
import { setUserLoggedInStatus } from 'store/reducers/resources/auth';
import { removeProfilePicture, uploadProfilePicture } from 'store/reducers/resources/user/api';
import { CHANGED_ROLE } from 'utils/constants';
import { USER_ROLE } from 'utils/user-role';
import { ROUTES } from '../role-navigation';
export const useLogOut = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return useCallback(() => {
        localStorage.clear();
        dispatch(setUserLoggedInStatus('loggedOut'));
        navigate(`${ROUTES.LOGIN}`, { replace: true });
    }, [dispatch, navigate]);
};
export const useSwitchToUser = () => {
    const navigate = useNavigate();
    return useCallback(() => {
        localStorage.setItem(CHANGED_ROLE, USER_ROLE.USER);
        navigate(`${ROUTES.GENERAL_USER.slice(1)}`);
    }, [navigate]);
};
export const useUploadProfilePicture = () => {
    const unwrap = useUnwrapAsyncThunk();
    return useCallback((uploadProfileParams) => {
        unwrap(uploadProfilePicture(uploadProfileParams));
    }, [unwrap]);
};
export const useRemoveProfilePicture = () => {
    const unwrap = useUnwrapAsyncThunk();
    return useCallback(() => {
        unwrap(removeProfilePicture());
    }, [unwrap]);
};
