import { jsx as _jsx } from "react/jsx-runtime";
import TextField, {} from '@mui/material/TextField';
import * as styles from './form-fields.module.scss';
const SelectInput = (props) => {
    const { input, meta: { touched, error }, children, ...others } = props;
    return (_jsx(TextField, { select: true, SelectProps: {
            native: true,
            classes: {
                select: styles.selectPadding,
            },
        }, ...input, helperText: touched && error, error: touched && !!error, variant: 'outlined', fullWidth: true, classes: {
            root: styles.inputRoot,
        }, ...others, children: children }));
};
export default SelectInput;
