import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import IxTooltip from 'components/basic/ix-tooltip';
import Language from 'utils/language';
import Error from './error';
import Success from './success';
import * as styles from './email-status.module.scss';
const EmailStatus = (props) => {
    const { failedReason, isFailed, userPrincipalEmail, notificationEmail } = props;
    const [selectedLanguage] = useState(Language.jap);
    const [formattedFailedReason, setFormattedFailedReason] = useState([]);
    useEffect(() => {
        if (!!failedReason) {
            const notNullStrings = failedReason
                .split(',')
                .filter((str) => str.length > 0)
                .map((str) => {
                let mail, message = '';
                const ERROR_OCCURRED = 'エラーが発生しました';
                if (str.includes(ERROR_OCCURRED)) {
                    const subStr = str.substring(str.indexOf(ERROR_OCCURRED) + ERROR_OCCURRED.length);
                    message = str.substring(str.indexOf(ERROR_OCCURRED), ERROR_OCCURRED.length);
                    if (subStr.includes(':')) {
                        mail = subStr.slice(1);
                        return _jsx(Error, { message: message, mail: mail }, str);
                    }
                    else if (subStr === '') {
                        return _jsx(Error, { message: message, mail: undefined }, str);
                    }
                    else {
                        return _jsx(Error, { message: message, mail: subStr }, str);
                    }
                }
                else {
                    return _jsx(Error, { message: str, mail: undefined }, str);
                }
            });
            if (userPrincipalEmail !== notificationEmail) {
                if (notNullStrings.length === 1) {
                    if (!!userPrincipalEmail && failedReason.includes(userPrincipalEmail)) {
                        if (!!notificationEmail) {
                            notNullStrings.push(_jsx(Success, { mail: notificationEmail }));
                        }
                    }
                    else if (!!notificationEmail && failedReason.includes(notificationEmail)) {
                        if (!!userPrincipalEmail) {
                            notNullStrings.push(_jsx(Success, { mail: userPrincipalEmail }));
                        }
                    }
                }
            }
            setFormattedFailedReason(notNullStrings);
        }
    }, [failedReason, notificationEmail, userPrincipalEmail]);
    return (_jsx(_Fragment, { children: isFailed === null ? (_jsx(Typography, { variant: 'caption', children: selectedLanguage.EmailPending })) : isFailed ? (_jsx(IxTooltip, { padding: 10, maxwidth: 'fit-content', title: _jsx(_Fragment, { children: formattedFailedReason.length > 0 ? (_jsx(_Fragment, { children: formattedFailedReason.map((reason, index) => (_jsx(React.Fragment, { children: _jsx("div", { className: styles.toolTipStyle, children: reason }) }, index))) })) : ('') }), children: _jsx(WarningIcon, { className: styles.error }) })) : (_jsx(Typography, { className: styles.success, variant: 'caption', children: selectedLanguage.EmailDelivered })) }));
};
export default EmailStatus;
