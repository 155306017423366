import { createSelector } from '@reduxjs/toolkit';
import { selectDeleteMultipleBranchStatus } from 'store/reducers/resources/branch/selectors';
import { selectMultipleDeleteDeliveryStatus } from 'store/reducers/resources/delivery-settings/selectors';
import { selectDeleteMultipleDepartmentStatus } from 'store/reducers/resources/department/selectors';
import { selectDeleteMultiTemplateStatus } from 'store/reducers/resources/template/selectors';
export const selectHasApiCalledFinished = createSelector([
    selectDeleteMultipleBranchStatus,
    selectDeleteMultipleDepartmentStatus,
    selectDeleteMultiTemplateStatus,
    selectMultipleDeleteDeliveryStatus,
], (branchStatus, departmentStatus, templateStatus, settingsStatus) => {
    const anySuccess = branchStatus === 'success' ||
        departmentStatus === 'success' ||
        templateStatus === 'success' ||
        settingsStatus === 'success';
    const anyRejected = branchStatus === 'rejected' ||
        departmentStatus === 'rejected' ||
        templateStatus === 'rejected' ||
        settingsStatus === 'rejected';
    return anySuccess && !anyRejected;
});
