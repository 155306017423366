// extracted by mini-css-extract-plugin
var _1 = "_applicationNameContainer_wLRB9";
var _2 = "_bodySurvey_pKnUC";
var _3 = "_container_dZOGK";
var _4 = "#1fa8a0";
var _5 = "_logoImageJustify_bUwMK";
var _6 = "_logoMainTop_J6TND";
var _7 = "_name_h65Zi";
var _8 = "#f44336";
var _9 = "_topLeft_12J7y";
var _a = "_topPart_catE_";
export { _1 as "applicationNameContainer", _2 as "bodySurvey", _3 as "container", _4 as "greenColor", _5 as "logoImageJustify", _6 as "logoMainTop", _7 as "name", _8 as "redColor", _9 as "topLeft", _a as "topPart" }
