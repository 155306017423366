// extracted by mini-css-extract-plugin
var _1 = "_colorCodeDeepBlueBold_FHkSs";
var _2 = "_colorCodeGrey_ZTF98";
var _3 = "_colorGreen_zT1nO";
var _4 = "_colorRed_iA0wT";
var _5 = "_container_PX40S";
var _6 = "_error_oX9rS";
var _7 = "_flex-justify-100p_sNgdy";
var _8 = "_fontColorBlack_u8ULc";
var _9 = "_fontColorCodeDarkBlue_HzYEo";
var _a = "_fontColorCodeDarkGray_fEXOK";
var _b = "_fontColorCodeLightGreen_OiU32";
var _c = "_fontColorCodeYellow_DydFg";
var _d = "_fontColorLight_wpODF";
var _e = "#1fa8a0";
var _f = "_padding-bottom-10_EWRsm";
var _10 = "_padding-top-10_rDTUa";
var _11 = "_primaryColorCode_URiaM";
var _12 = "_primaryDarkColor_cGovw";
var _13 = "#f44336";
var _14 = "_safeState_VMcHL";
var _15 = "_success_aWD9p";
var _16 = "_toolTipStyle_M6DT3";
var _17 = "_valueBold_SeAys";
export { _1 as "colorCodeDeepBlueBold", _2 as "colorCodeGrey", _3 as "colorGreen", _4 as "colorRed", _5 as "container", _6 as "error", _7 as "flex-justify-100p", _8 as "fontColorBlack", _9 as "fontColorCodeDarkBlue", _a as "fontColorCodeDarkGray", _b as "fontColorCodeLightGreen", _c as "fontColorCodeYellow", _d as "fontColorLight", _e as "greenColor", _f as "padding-bottom-10", _10 as "padding-top-10", _11 as "primaryColorCode", _12 as "primaryDarkColor", _13 as "redColor", _14 as "safeState", _15 as "success", _16 as "toolTipStyle", _17 as "valueBold" }
