import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { handleToastOpt } from 'store/reducers/ui/toast';
export const useHideToast = () => {
    const dispatch = useAppDispatch();
    return useCallback(() => {
        dispatch(handleToastOpt({
            open: false,
            message: null,
            severity: null,
        }));
    }, [dispatch]);
};
