import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useGetDomainName, useSplitLocationHook } from '_shared/hooks';
import { useAppSelector } from 'store/hooks';
import { selectUserLoggedInStatus } from 'store/reducers/resources/auth/selectors';
import { API_BASE_URL, DOMAIN } from 'utils/constants';
import { decryptMessage, encryptMessage } from 'utils/encrypt-decrypt';
import { ROUTES } from '../role-navigation';
export const useCheckDomainValidity = () => {
    return useCallback((domain) => {
        return axios.get(`${API_BASE_URL}/domain-info`, {
            params: {
                domain,
            },
        });
    }, []);
};
export const useDomainNavigation = () => {
    const [isDomainValid, setIsDomainValid] = useState(false);
    const [isApiRunning, setIsApiRunning] = useState(true);
    const domain = useGetDomainName();
    const checkDomainValidity = useCheckDomainValidity();
    const useStatus = useAppSelector(selectUserLoggedInStatus);
    useEffect(() => {
        const savedDomain = localStorage.getItem(DOMAIN);
        if (savedDomain === null) {
            checkDomainValidity(domain)
                .then((res) => {
                if (res.data) {
                    setIsDomainValid(true);
                    localStorage.setItem(DOMAIN, encryptMessage(domain));
                }
                else {
                    localStorage.clear();
                }
                setIsApiRunning(false);
            })
                .catch(() => {
                setIsApiRunning(false);
            });
        }
        else if (domain?.trim() !== decryptMessage(savedDomain)) {
            setIsApiRunning(false);
            setIsDomainValid(false);
        }
        else {
            setIsApiRunning(false);
            setIsDomainValid(true);
        }
    }, [checkDomainValidity, domain, useStatus]);
    return { isApiRunning, isDomainValid };
};
export const useCheckIfDomainEmpty = () => {
    const [isDomainEmpty, setIsDomainEmpty] = useState(false);
    const splitLocation = useSplitLocationHook();
    useEffect(() => {
        const userSpecificRoutes = [ROUTES.ADMIN.slice(1), ROUTES.GENERAL_USER.slice(1), ROUTES.DEPARTMENT_ADMIN.slice(1)];
        if (splitLocation.length === 1) {
            setIsDomainEmpty(true);
        }
        else if (splitLocation.length > 1 && userSpecificRoutes.includes(splitLocation[0])) {
            setIsDomainEmpty(true);
        }
    }, [splitLocation]);
    return { isDomainEmpty };
};
