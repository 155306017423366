import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Avatar from 'react-avatar';
import notFoundImage from './image-not-available.png';
import * as styles from './user-avatar.module.scss';
const UserAvatar = (props) => {
    const { userName, profilePictureUrl } = props;
    return (_jsx(_Fragment, { children: !!profilePictureUrl ? (_jsx("img", { src: profilePictureUrl, className: styles.imageSmall, alt: 'avatar', onError: ({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = notFoundImage;
            } })) : (_jsx(Avatar, { name: userName, size: '30', round: true })) }));
};
export default UserAvatar;
