import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useSetDeletableRowData, useSetIsAllChecked } from '_shared/hooks';
import IxTooltip from 'components/basic/ix-tooltip';
import TableCheckbox from 'components/basic/table-checkbox';
import { useAppSelector } from 'store/hooks';
import { selectDeletableRowData, selectTotalDeletableCount } from 'store/reducers/ui/multi-delete/selectors';
import * as styles from '../shared.module.scss';
function CommonCheckbox(props) {
    const { item, column } = props;
    const deletableRowData = useAppSelector(selectDeletableRowData);
    const totalDeletable = useAppSelector(selectTotalDeletableCount);
    const handleSetRowData = useSetDeletableRowData();
    const handleAllDeleteCheck = useSetIsAllChecked();
    return (_jsx(_Fragment, { children: item.IsDeletable ? (_jsx(TableCheckbox, { checked: item.IsDeletable && deletableRowData.includes(item.Id), onChange: (event) => {
                if (event.target.checked) {
                    handleSetRowData(item.Id);
                    handleAllDeleteCheck(deletableRowData.length + 1 === totalDeletable);
                }
                else {
                    handleSetRowData(deletableRowData.filter((i) => i !== item.Id));
                    handleAllDeleteCheck(false);
                }
            } })) : (_jsx(IxTooltip, { title: _jsx(_Fragment, { children: _jsx(Typography, { className: styles.checkBoxDisable, variant: 'subtitle2', children: column.disableCause ? column.disableCause(item) : null }) }), padding: 10, children: _jsx("div", { style: {
                    cursor: 'pointer',
                }, children: _jsx(TableCheckbox, { disabled: true }) }) })) }));
}
export default CommonCheckbox;
