import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { IDLE_TIME, REDIRECT_URL } from 'utils/constants';
import { useLogOut } from '../utility/show-user-avatar/hooks';
import LeftNavigation from './left-navigation';
const getIdleTime = () => {
    const lastTabClose = localStorage.getItem('last_tab_close');
    if (lastTabClose !== null) {
        const now = moment();
        const then = moment(lastTabClose);
        const delta = IDLE_TIME - now.diff(then, 'milliseconds');
        if (delta > 0) {
            return delta;
        }
        else {
            return 5;
        }
    }
    return IDLE_TIME;
};
const LeftNavigationContainer = () => {
    const LAST_TAB_CLOSE = 'last_tab_close';
    const logout = useLogOut();
    useIdleTimer({
        onIdle: () => {
            logout();
        },
        timeout: getIdleTime(),
        crossTab: true,
    });
    const entries = performance.getEntriesByType('navigation');
    useEffect(() => {
        localStorage.removeItem(REDIRECT_URL);
        const handleTabClose = (_event) => {
            localStorage.setItem(LAST_TAB_CLOSE, moment().format());
        };
        window.addEventListener('beforeunload', handleTabClose);
        return function () {
            window.removeEventListener('beforeunload', () => {
                localStorage.removeItem(LAST_TAB_CLOSE);
            });
        };
    }, []);
    useEffect(() => {
        entries.forEach((entry) => {
            if (entry.type === 'reload') {
                localStorage.removeItem(LAST_TAB_CLOSE);
            }
        });
    }, [entries]);
    return (_jsx(_Fragment, { children: _jsx(LeftNavigation, {}) }));
};
export default LeftNavigationContainer;
