import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import Language from 'utils/language';
import * as styles from './status.module.scss';
const Index = ({ value }) => {
    const [selectedLanguage] = useState(Language.jap);
    switch (value) {
        case 'open':
            return _jsx("div", { className: styles.statusInProgress, children: selectedLanguage.StatusInProgress });
        case 'closed':
            return _jsx("div", { className: styles.statusClose, children: selectedLanguage.StatusClose });
        case 'scheduled':
            return _jsx("div", { className: styles.statusScheduled, children: selectedLanguage.Scheduled });
        case 'cancelled':
            return _jsx("div", { className: styles.statusCancelled, children: selectedLanguage.Cancelled });
        default:
            return null;
    }
};
export default Index;
