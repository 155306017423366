import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import IxBtnWhite from 'components/basic/ix-btn-white';
import IxMdButton from 'components/basic/ix-md-button';
import Language from 'utils/language';
import * as styles from './confirmation.module.scss';
const ViewConfirmation = ({ templateConfirmModal, setTemplateConfirmModal, setAlertConfirmation, }) => {
    const [selectedLanguage] = useState(Language.jap);
    return (_jsxs(Dialog, { maxWidth: 'sm', className: styles.modalView, "aria-labelledby": 'customized-dialog-title', open: templateConfirmModal, children: [_jsx("div", { style: { paddingTop: 40 } }), _jsx(DialogContent, { classes: {
                    root: styles.dialogContentRoot,
                }, children: selectedLanguage.ConfirmationMessageForAllUser }), _jsx(DialogActions, { classes: {
                    root: styles.dialogActionRoot,
                }, children: _jsxs("div", { className: 'button-container', children: [_jsx(IxBtnWhite, { onClick: () => {
                                setTemplateConfirmModal(false);
                                setAlertConfirmation(false);
                            }, children: selectedLanguage.BtnNo }), _jsx(IxMdButton, { variant: 'contained', onClick: () => {
                                setTemplateConfirmModal(false);
                                setAlertConfirmation(true);
                            }, label: selectedLanguage.BtnYes })] }) })] }));
};
export default ViewConfirmation;
