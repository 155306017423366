import { jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Language from 'utils/language';
import * as styles from './situation.module.scss';
export const getPersonalSafety = (value) => {
    if (value === 1) {
        return Language.jap.SafePersonnel;
    }
    if (value === 2) {
        return Language.jap.StatusMinorInjury;
    }
    return Language.jap.SeriouslyInjured;
};
const Index = ({ status }) => {
    const [selectedLanguage] = useState(Language.jap);
    if (status === null) {
        return null;
    }
    if (status === 1) {
        return _jsxs("span", { className: styles.fontColorCodeLightGreen, children: [" ", selectedLanguage.StatusSafe, " "] });
    }
    return _jsxs("span", { className: styles.colorRed, children: [" ", getPersonalSafety(status), " "] });
};
export default Index;
