import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';
import { useEffect, useRef, useState, lazy } from 'react';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/loader';
import { useAppSelector, useUnwrapAsyncThunk } from 'store/hooks';
import { getUserInfoByMail } from 'store/reducers/resources/user/api';
import { selectGetUserInfoByMailStatus, selectProfilePictureUrl } from 'store/reducers/resources/user/selectors';
import { CHANGED_ROLE, TOKEN, USER_ROLE_INFO } from 'utils/constants';
import Language from 'utils/language';
import { USER_ROLE } from 'utils/user-role';
import { ROUTES } from '../role-navigation';
import { useScreenSizeContext } from '../screen-size-context';
import { useLogOut, useSwitchToUser } from './hooks';
import * as styles from './avatar.module.scss';
const ProfileUpload = lazy(() => import('./profile-upload'));
const ForgetPasswordContainer = lazy(() => import('pages/normal-user-page/forget-password/forget-password-container'));
const AddEditUserContainer = lazy(() => import('pages/user/add-edit-user/add-edit-user-container'));
const ShowUserAvatar = (props) => {
    const { avatarUser } = props;
    const unwrap = useUnwrapAsyncThunk();
    const navigate = useNavigate();
    const iconRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPasswordResetModal, setOpenResetPasswordModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [selectedLanguage] = useState(Language.jap);
    const [hasChangedUserRole, setHasChangedUserRole] = useState(false);
    const [openImageUpload, setOpenImageUpload] = useState(false);
    const getUserInfoByMailStatus = useAppSelector(selectGetUserInfoByMailStatus);
    const profilePicture = useAppSelector(selectProfilePictureUrl);
    const { screenWidth } = useScreenSizeContext();
    const { isO365 } = JSON.parse(localStorage.getItem(TOKEN));
    const { UserPrincipalName, UserRole } = JSON.parse(localStorage.getItem(USER_ROLE_INFO));
    const changedRole = localStorage.getItem(CHANGED_ROLE);
    useEffect(() => {
        if (changedRole === null) {
            setHasChangedUserRole(false);
        }
        else {
            setHasChangedUserRole(true);
        }
    }, [changedRole]);
    const handleClick = () => {
        setAnchorEl(iconRef.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlePasswordModal = () => {
        setAnchorEl(null);
        setOpenResetPasswordModal(true);
    };
    const handleUpdateProfile = () => {
        setAnchorEl(null);
        unwrap(getUserInfoByMail(UserPrincipalName)).then(() => setOpenUpdateModal(true));
    };
    const handleLogout = useLogOut();
    const switchToUser = useSwitchToUser();
    return (_jsxs(_Fragment, { children: [_jsx(Loading, { loading: getUserInfoByMailStatus === 'pending' }), _jsxs("div", { className: 'flex cursor-pointer', onClick: handleClick, children: [_jsx("div", { children: !!profilePicture ? (_jsx("img", { src: profilePicture, className: styles.imageSmall, alt: 'avatar' })) : (_jsx(Avatar, { name: avatarUser.Name, size: '30', round: true })) }), screenWidth === undefined || screenWidth > 650 ? (_jsx("div", { className: clsx([styles.userNameTitle, 'text-ellipsis']), children: avatarUser.Name })) : null, _jsx("div", { children: _jsx(KeyboardArrowDownIcon, { className: clsx([styles.downArrow, styles.primaryColorCode]), ref: iconRef }) })] }), anchorEl ? (_jsxs(Menu, { id: 'simple-menu', anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, className: styles.profilePopUp, children: [_jsx(MenuItem, { onClick: handleUpdateProfile, children: selectedLanguage.UpdateProfile }), isO365 ? null : _jsx(MenuItem, { onClick: handlePasswordModal, children: selectedLanguage.ChangePassword }), (UserRole === USER_ROLE.ADMIN || UserRole === USER_ROLE.MANAGER) && !hasChangedUserRole ? (_jsx(MenuItem, { onClick: () => {
                            switchToUser();
                            handleClose();
                        }, children: "\u4E00\u822C\u30E6\u30FC\u30B6\u30FC\u306B\u5207\u308A\u66FF\u3048\u308B" })) : null, hasChangedUserRole && UserRole !== USER_ROLE.USER ? (_jsx(MenuItem, { onClick: () => {
                            localStorage.removeItem(CHANGED_ROLE);
                            navigate(ROUTES.HOME);
                        }, children: UserRole === USER_ROLE.ADMIN
                            ? '管理者に切り替える'
                            : UserRole === USER_ROLE.MANAGER
                                ? '部署管理者に切り替える'
                                : '' })) : null, _jsx(MenuItem, { onClick: () => {
                            setOpenImageUpload(true);
                            handleClose();
                        }, children: selectedLanguage.UploadProfile }), _jsx(MenuItem, { onClick: handleLogout, children: selectedLanguage.LogOut })] })) : null, openPasswordResetModal ? (_jsx(ForgetPasswordContainer, { openModal: openPasswordResetModal, setOpenModal: setOpenResetPasswordModal })) : null, openUpdateModal ? (_jsx(AddEditUserContainer, { openModal: openUpdateModal, setOpenModal: setOpenUpdateModal, operationType: 'profile_update' })) : null, openImageUpload ? _jsx(ProfileUpload, { openModal: openImageUpload, setOpenModal: setOpenImageUpload }) : null] }));
};
export default ShowUserAvatar;
