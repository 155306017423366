// extracted by mini-css-extract-plugin
var _1 = "_applicationTitle_AccVn";
var _2 = "_colorCodeDeepBlueBold_KTz_W";
var _3 = "_colorCodeGrey_ajEiv";
var _4 = "_colorGreen_xamaI";
var _5 = "_colorRed_Tzujf";
var _6 = "_fakeDivToOverlapBorder__F9Qk";
var _7 = "_flex-justify-100p_RzC36";
var _8 = "_fontColorBlack_K7Q74";
var _9 = "_fontColorCodeDarkBlue_eOeVx";
var _a = "_fontColorCodeDarkGray_dwwWZ";
var _b = "_fontColorCodeLightGreen_GAr76";
var _c = "_fontColorCodeYellow_qvHnJ";
var _d = "_fontColorLight__S2BR";
var _e = "#1fa8a0";
var _f = "_headerContainer__lROT";
var _10 = "_hide_oqz_A";
var _11 = "_iconImgContainer_efRvt";
var _12 = "_leftNavigationListContainer_rlefM";
var _13 = "_logoImageJustify_kiRGY";
var _14 = "_logoMainTop_MNEP8";
var _15 = "_menuButton_opa7k";
var _16 = "_menuIconContainer_TvgpE";
var _17 = "_menuItem_js5d_";
var _18 = "_menuText_N4Sxi";
var _19 = "_navButton_esR1m";
var _1a = "_onlyLogoWidth_TfACF";
var _1b = "_padding-bottom-10_SRd_2";
var _1c = "_padding-top-10_R4KNp";
var _1d = "_primaryColorCode_SBPaz";
var _1e = "_primaryDarkColor_d90SS";
var _1f = "#f44336";
var _20 = "_safeState_eEosP";
var _21 = "_selected__e3mm";
var _22 = "_valueBold_BJyf2";
export { _1 as "applicationTitle", _2 as "colorCodeDeepBlueBold", _3 as "colorCodeGrey", _4 as "colorGreen", _5 as "colorRed", _6 as "fakeDivToOverlapBorder", _7 as "flex-justify-100p", _8 as "fontColorBlack", _9 as "fontColorCodeDarkBlue", _a as "fontColorCodeDarkGray", _b as "fontColorCodeLightGreen", _c as "fontColorCodeYellow", _d as "fontColorLight", _e as "greenColor", _f as "headerContainer", _10 as "hide", _11 as "iconImgContainer", _12 as "leftNavigationListContainer", _13 as "logoImageJustify", _14 as "logoMainTop", _15 as "menuButton", _16 as "menuIconContainer", _17 as "menuItem", _18 as "menuText", _19 as "navButton", _1a as "onlyLogoWidth", _1b as "padding-bottom-10", _1c as "padding-top-10", _1d as "primaryColorCode", _1e as "primaryDarkColor", _1f as "redColor", _20 as "safeState", _21 as "selected", _22 as "valueBold" }
