import { createContext, useContext } from 'react';
const ScreenSizeContext = createContext(null);
const { Provider } = ScreenSizeContext;
function useScreenSizeContext() {
    const screenCtx = useContext(ScreenSizeContext);
    if (!screenCtx) {
        throw new Error('Cannot use `useScreenSizeContext` outside ScreenSizeProvider');
    }
    return screenCtx;
}
export { Provider as ScreenSizeProvider, useScreenSizeContext };
