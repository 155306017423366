import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid, OutlinedInput, Pagination } from '@mui/material';
import parseInt from 'lodash/parseInt';
import { useState } from 'react';
import IxSelect from 'components/basic/ix-select';
import { useScreenSizeContext } from 'components/utility/screen-size-context';
import { useAppSelector } from 'store/hooks';
import { selectCurrentPage, selectRowPerPage, selectShouldShowZeroPage } from 'store/reducers/ui/pagination/selectors';
import { useSetCurrentPage, useSetRecordPerPage } from './hooks';
import { selectTotalPage } from './selectors';
import * as styles from './pagination.module.scss';
const CustomPagination = () => {
    const { isMobile } = useScreenSizeContext();
    const rowPerPage = useAppSelector(selectRowPerPage);
    const currentPage = useAppSelector(selectCurrentPage);
    const totalPage = useAppSelector(selectTotalPage);
    const shouldShowZeroPage = useAppSelector(selectShouldShowZeroPage);
    const setRecordPerPage = useSetRecordPerPage();
    const setCurrentPage = useSetCurrentPage();
    const [tempCurrentPage, setTempCurrentPage] = useState('');
    const onPageChange = (page) => {
        setCurrentPage(page);
        setTempCurrentPage('');
        const dataTable = document.querySelector("table[id='ix_data_table']");
        if (dataTable) {
            dataTable.scrollIntoView();
        }
    };
    return (_jsx(Box, { px: 2, py: 1.25, borderTop: '1px solid #e5e5e5', children: _jsxs(Grid, { container: true, spacing: 2, alignItems: 'center', children: [isMobile ? null : (_jsx(Grid, { item: true, xs: 4, children: _jsxs("div", { className: styles.recPerPage, children: [_jsx("div", { className: styles.text, children: "\u8868\u793A\u4EF6\u6570" }), _jsx("div", { children: _jsx(IxSelect, { fullWidth: false, rootClassName: styles.inputPadding, value: rowPerPage, onChange: (event) => {
                                        setRecordPerPage(parseInt(event.target.value));
                                    }, children: [10, 20, 50, 100].map((value) => {
                                        return _jsx("option", { children: value }, value);
                                    }) }) })] }) })), _jsx(Grid, { item: true, xs: isMobile ? 12 : 4, children: _jsx(Box, { display: 'flex', justifyContent: 'center', children: _jsx(Pagination, { count: shouldShowZeroPage ? 0 : totalPage, variant: 'outlined', shape: 'rounded', color: 'primary', size: 'small', className: styles.pagination, page: shouldShowZeroPage ? 0 : currentPage, onChange: (event, page) => {
                                onPageChange(page);
                            }, siblingCount: isMobile ? 0 : undefined }) }) }), isMobile ? null : (_jsx(Grid, { item: true, xs: 4, children: _jsxs("div", { className: styles.goTo, children: [_jsx("div", { className: styles.pageInput, children: _jsx(OutlinedInput, { fullWidth: false, classes: {
                                        input: styles.inputPadding,
                                    }, placeholder: '\u30DA\u30FC\u30B8\u756A\u53F7', onChange: (event) => {
                                        const { value } = event.target;
                                        if (value === '') {
                                            setTempCurrentPage('');
                                        }
                                        else {
                                            setTempCurrentPage(parseInt(value));
                                        }
                                    }, value: tempCurrentPage, onKeyDown: (event) => {
                                        if (event.key === 'Enter') {
                                            const { value } = event.target;
                                            if (value === '') {
                                                setTempCurrentPage('');
                                                setCurrentPage(1);
                                            }
                                            else {
                                                setTempCurrentPage(parseInt(value));
                                                setCurrentPage(parseInt(value));
                                            }
                                        }
                                    } }) }), _jsx(Button, { color: 'primary', variant: 'contained', classes: {
                                    root: styles.button,
                                }, onClick: () => {
                                    if (typeof tempCurrentPage === 'number') {
                                        setCurrentPage(tempCurrentPage);
                                    }
                                    if (tempCurrentPage === '') {
                                        setCurrentPage(1);
                                    }
                                }, children: "\u79FB\u52D5" })] }) }))] }) }));
};
export default CustomPagination;
