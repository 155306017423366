import { jsx as _jsx } from "react/jsx-runtime";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetRoleNav, useGetDomainName } from '_shared/hooks';
import * as styles from './breadcrumb.module.scss';
const BreadcrumbMenu = (props) => {
    const { routes } = props;
    const navigate = useNavigate();
    const roleNav = useGetRoleNav();
    const domain = useGetDomainName();
    return (_jsx(Breadcrumbs, { "aria-label": 'breadcrumb', className: styles.primaryColorCode, separator: _jsx(ArrowForwardIosIcon, { className: styles.breadCrumbsSeparator }), classes: {
            root: styles.breadCrumbRoot,
        }, children: _.orderBy(routes, 'index', 'asc').map((route, i) => {
            return (_jsx(Link, { color: 'inherit', component: 'button', underline: 'none', className: clsx([
                    { 'cursor-pointer': i !== routes.length - 1 },
                    {
                        [styles.anchorNoStyleNoCursor]: i === routes.length - 1,
                    },
                ]), onClick: () => {
                    if (typeof route.customOnClick === 'function') {
                        route.customOnClick();
                    }
                    else {
                        navigate({
                            pathname: `/${domain}${roleNav}${route.href}`,
                        });
                    }
                }, children: route.text }, route.index));
        }) }));
};
export default BreadcrumbMenu;
