// extracted by mini-css-extract-plugin
var _1 = "_colorCodeDeepBlueBold_p4cTY";
var _2 = "_colorCodeGrey_Uw01s";
var _3 = "_colorGreen_DF312";
var _4 = "_colorRed_ov9k_";
var _5 = "_fontColorBlack_AwjBp";
var _6 = "_fontColorCodeDarkBlue_fWgIK";
var _7 = "_fontColorCodeDarkGray_T7QRh";
var _8 = "_fontColorCodeLightGreen_xdP1i";
var _9 = "_fontColorCodeYellow_c3Qg7";
var _a = "_fontColorLight_RE_CD";
var _b = "#1fa8a0";
var _c = "_primaryColorCode_bFOMb";
var _d = "_primaryDarkColor_X31t1";
var _e = "#f44336";
var _f = "_safeState_y1t4s";
var _10 = "_valueBold_RT95z";
export { _1 as "colorCodeDeepBlueBold", _2 as "colorCodeGrey", _3 as "colorGreen", _4 as "colorRed", _5 as "fontColorBlack", _6 as "fontColorCodeDarkBlue", _7 as "fontColorCodeDarkGray", _8 as "fontColorCodeLightGreen", _9 as "fontColorCodeYellow", _a as "fontColorLight", _b as "greenColor", _c as "primaryColorCode", _d as "primaryDarkColor", _e as "redColor", _f as "safeState", _10 as "valueBold" }
