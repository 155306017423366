import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'components/utility/role-navigation';
import { useScreenSizeContext } from 'components/utility/screen-size-context';
import * as styles from '../domain.module.scss';
const InvalidDomain = ({ message }) => {
    const { isMobile } = useScreenSizeContext();
    const navigate = useNavigate();
    useEffect(() => {
        document.body.style.padding = '0';
    }, []);
    return (_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', children: _jsxs(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', children: [_jsx("div", { children: _jsx("img", { src: '../../../Content/custom-icons/safety_logo.png', alt: 'logo', width: 190, height: 35 }) }), _jsx("div", { style: {
                        paddingBottom: 10,
                    }, children: _jsx("p", { style: {
                            fontSize: isMobile ? 25 : 32,
                            fontWeight: 600,
                            color: '#00000057',
                            margin: 0,
                        }, children: message }) }), _jsx("div", { className: styles.loginBox, children: _jsxs(Box, { display: 'flex', children: ["Go to", ' ', _jsx("div", { className: styles.loginText, onClick: () => {
                                    localStorage.clear();
                                    navigate(ROUTES.LOGIN);
                                }, children: "Login" })] }) })] }) }));
};
export default InvalidDomain;
