import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as styles from './email-status.module.scss';
const Error = (props) => {
    const { message, mail } = props;
    if (!!mail) {
        return (_jsxs("div", { className: styles.container, children: [_jsxs("div", { children: [mail, ":"] }), _jsx("div", { className: styles.error, children: message })] }));
    }
    else {
        return _jsx("div", { className: styles.error, children: message });
    }
};
export default Error;
