import Switch, { switchClasses } from '@mui/material/Switch';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
const BlueSwitch = styled(Switch)(() => ({
    [`&.${switchClasses.switchBase}`]: {
        [`&.${switchClasses.checked}`]: {
            color: blue[500],
            [`& + .${switchClasses.track}`]: {
                backgroundColor: blue[500],
            },
        },
    },
}));
export default BlueSwitch;
