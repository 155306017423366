import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell, TableHead, TableRow } from '@mui/material';
import TableSortLabel, { tableSortLabelClasses } from '@mui/material/TableSortLabel';
import React from 'react';
import AllCheck from '../../_shared/all-check';
function EnhancedTableHeader(props) {
    const { columns, orderBy, order, onRequestSort, data } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    if (columns.length > 0) {
        return (_jsx(TableHead, { children: _jsx(TableRow, { children: columns.map((column, index) => (_jsx(React.Fragment, { children: _jsx(TableCell, { align: column?.align || 'left', sortDirection: orderBy === column.id ? order : false, style: {
                            minWidth: column.minWidth,
                        }, component: 'th', children: column.id === 'Checkbox' ? (_jsx(AllCheck, { data: data })) : (_jsx(TableSortLabel, { active: orderBy === column.id && column.canBeSorted, direction: orderBy === column.id ? order : 'asc', onClick: column.canBeSorted ? createSortHandler(column.id) : undefined, hideSortIcon: !column.canBeSorted, style: {
                                cursor: `${!column.canBeSorted && 'auto'}`,
                            }, sx: {
                                [`&.${tableSortLabelClasses.root}`]: {
                                    [`&.${tableSortLabelClasses.active}`]: {
                                        color: '#969696',
                                        [`.${tableSortLabelClasses.icon}`]: {
                                            color: '#969696',
                                        },
                                    },
                                },
                                [`&.${tableSortLabelClasses.icon}`]: {
                                    color: '#969696',
                                },
                            }, children: column.label })) }) }, index))) }) }));
    }
    else {
        return null;
    }
}
export default EnhancedTableHeader;
