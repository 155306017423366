import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useState } from 'react';
import Language from 'utils/language';
import * as styles from './icon.module.scss';
const FilterIcon = ({ showFilterOption }) => {
    const [selectedLanguage] = useState(Language.jap);
    return (_jsxs(_Fragment, { children: [_jsx(FilterListIcon, { fontSize: 'small', className: clsx([styles.iconStyle, styles.mr, { [styles.iconInvert]: showFilterOption }]) }), _jsx(Box, { component: Typography, className: styles.iconStyle, fontSize: 12, children: selectedLanguage.FilterOptions })] }));
};
export default FilterIcon;
