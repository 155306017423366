import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography, Grid, Paper } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';
import platform from 'platform-detect';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import IxTooltip from 'components/basic/ix-tooltip';
import { useAppSelector } from 'store/hooks';
import { selectDeletableRowData } from 'store/reducers/ui/multi-delete/selectors';
import Language from 'utils/language';
import CommonCheckbox from '../_shared/common-checkbox';
import MobileTableTopSection from './top-section/mobile-table-top-section';
import * as styles from './mobileTableCard.module.scss';
function Index(props) {
    const { columns, defaultSortedColumn, data, onDeleteHandler, onEditHandler, order: userOrder, multiDeleteClick, hasCheckBox, customRowClass, rowOnClickHandler, totalCount, shouldClientSideSort, } = props;
    const [order] = useState(userOrder || 'asc');
    const [orderBy] = useState(defaultSortedColumn);
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
    const [selectedLanguage] = useState(Language.jap);
    const deletableRowData = useAppSelector(selectDeletableRowData);
    const listRef = useRef(null);
    useEffect(() => {
        if (listRef.current) {
            const elem = listRef.current;
            const isIOS = platform.ios;
            setHasVerticalScroll(elem.scrollHeight > elem.clientHeight && !isIOS);
        }
    }, []);
    const orderedData = useMemo(() => {
        return shouldClientSideSort ? _.orderBy(data, [orderBy], [order]) : data;
    }, [data, order, orderBy, shouldClientSideSort]);
    return (_jsxs(_Fragment, { children: [hasCheckBox ? (_jsx(MobileTableTopSection, { hasVerticalScroll: hasVerticalScroll, data: data, multiDeleteClick: multiDeleteClick, totalCount: totalCount })) : null, _jsx(Paper, { className: 'scroll-paper-style', id: 'scroll', elevation: 0, ref: listRef, children: orderedData.map((item) => {
                    let rowHandlerReturn = undefined;
                    if (typeof rowOnClickHandler === 'function') {
                        rowHandlerReturn = rowOnClickHandler(item);
                    }
                    else {
                        rowHandlerReturn = undefined;
                    }
                    return (_jsx("div", { className: clsx([
                            { [styles.tableRowSelected]: deletableRowData.includes(item.Id) },
                            ...[Boolean(customRowClass) ? (customRowClass ? customRowClass(item) : '') : ''],
                            styles.mobileTableCard,
                        ]), onClick: () => {
                            if (typeof rowHandlerReturn === 'function') {
                                rowHandlerReturn();
                            }
                        }, children: _jsx(Grid, { container: true, direction: 'column', children: columns.map((column) => (_jsx(React.Fragment, { children: !!column.hideInMobile ? null : (_jsx(_Fragment, { children: column.id === 'Checkbox' ? (_jsx(Grid, { item: true, xs: 12, style: { textAlign: 'right' }, children: _jsx(CommonCheckbox, { item: item, column: column }) })) : column.id === 'Action' ? (column.actionType === 'default' ? (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 4, className: clsx(styles.columnNameFont, styles.columnInfoFont), children: column.label }), _jsx(Grid, { item: true, xs: 8, children: _jsxs("div", { className: styles.editDeleteBtnContainer, children: [_jsx(IxTooltip, { padding: 10, title: _jsx(Typography, { className: styles.editDeleteTooltipStyle, children: selectedLanguage.EditIconTitle }), children: _jsx("div", { className: clsx([styles.iconContainer]), onClick: () => {
                                                                    if (onEditHandler) {
                                                                        onEditHandler(item);
                                                                    }
                                                                }, children: _jsx(CreateIcon, { fontSize: 'small', className: styles.editDeleteIconsStyle }) }) }), 'IsDeletable' in item && item.IsDeletable && (_jsx(IxTooltip, { padding: 10, title: _jsx(Typography, { className: styles.editDeleteTooltipStyle, children: selectedLanguage.DeleteIconTitle }), children: _jsx("div", { onClick: () => {
                                                                    if (onDeleteHandler) {
                                                                        onDeleteHandler(item);
                                                                    }
                                                                }, className: clsx([styles.iconContainer]), children: _jsx(DeleteOutlineIcon, { fontSize: 'small', className: styles.editDeleteIconsStyle }) }) }))] }) })] })) : (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 4, className: clsx(styles.columnNameFont, styles.columnInfoFont), children: column.label }), _jsx(Grid, { item: true, xs: 8, children: column.customActions ? column.customActions(item) : null })] }))) : (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 4, className: clsx(styles.columnNameFont, styles.columnInfoFont), children: column.label }), _jsx(Grid, { item: true, xs: 8, className: clsx(styles.columnNameFont, {
                                                    [styles.textEllipsis]: column.type === 'string',
                                                }), children: column.render ? column.render(item) : null })] })) })) }, column.id))) }) }, item.Id));
                }) })] }));
}
export default Index;
