import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import Autocomplete, {} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Language from 'utils/language';
import * as styles from './form-fields.module.scss';
const AutoCompleteInput = (props) => {
    const { input, meta: { touched }, placeholder, ...others } = props;
    return (_jsx(Autocomplete, { value: input.value || [], onChange: (e, newValue) => {
            input.onChange(newValue);
        }, multiple: true, fullWidth: true, renderTags: (value, getTagProps) => value.map((option, index) => (_createElement(Chip, { variant: 'outlined', label: option, ...getTagProps({ index }), key: option }))), classes: {
            inputRoot: styles.autocompletePad,
        }, ...others, renderInput: (params) => (_jsx(TextField, { variant: 'outlined', placeholder: input.value.length > 0 ? '' : placeholder, helperText: touched && input.value.length === 0 ? Language.jap.RequiredField : null, onBlur: (event) => {
                input.onBlur(event);
            }, error: touched && input.value.length === 0, ...params })) }));
};
export default AutoCompleteInput;
