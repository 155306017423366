import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import ShowUserAvatar from 'components/utility/show-user-avatar';
import { useAppSelector, useUnwrapAsyncThunk } from 'store/hooks';
import { getUserInfoByMail } from 'store/reducers/resources/user/api';
import { selectRemoveProfilePictureApiStatus, selectSelectedUser, selectUpdatedUserInfo, } from 'store/reducers/resources/user/selectors';
import { TOKEN, USER_ROLE_INFO } from 'utils/constants';
import Language from 'utils/language';
import * as styles from '../navigation.module.scss';
const ApplicationTitle = () => {
    const userRoleInfo = localStorage.getItem(USER_ROLE_INFO);
    const tokenObj = localStorage.getItem(TOKEN);
    const updatedUserInfo = useAppSelector(selectUpdatedUserInfo);
    const selectedUser = useAppSelector(selectSelectedUser);
    const removeProfilePictureApiStatus = useAppSelector(selectRemoveProfilePictureApiStatus);
    const unwrap = useUnwrapAsyncThunk();
    const [selectedLang] = useState(Language.jap);
    const [loggedInUserDetails, setLoggedInUserDetails] = useState({
        Name: '',
    });
    const [userPrincipalName, setUserPrincipalName] = useState('');
    const [isO365User, setIsO365User] = useState(false);
    useEffect(() => {
        if (userRoleInfo !== null) {
            const { UserPrincipalName } = JSON.parse(userRoleInfo);
            setUserPrincipalName(UserPrincipalName);
        }
        if (tokenObj !== null) {
            const { isO365 } = JSON.parse(tokenObj);
            setIsO365User(!!isO365);
        }
    }, [tokenObj, userRoleInfo]);
    useEffect(() => {
        if (updatedUserInfo.hasUpdated ||
            updatedUserInfo.email === userPrincipalName ||
            selectedUser?.UserPrincipalName === userPrincipalName) {
            setLoggedInUserDetails({
                Name: updatedUserInfo.hasUpdated || updatedUserInfo.email === userPrincipalName
                    ? updatedUserInfo.name
                    : selectedUser?.Name,
            });
        }
    }, [updatedUserInfo, userPrincipalName, isO365User, selectedUser]);
    useEffect(() => {
        if (userPrincipalName !== '' && typeof userPrincipalName === 'string') {
            unwrap(getUserInfoByMail(userPrincipalName));
        }
    }, [userPrincipalName, unwrap]);
    useEffect(() => {
        if (removeProfilePictureApiStatus === 'success') {
            unwrap(getUserInfoByMail(userPrincipalName));
        }
    }, [removeProfilePictureApiStatus, unwrap, userPrincipalName]);
    return (_jsxs("div", { className: styles.headerContainer, children: [_jsx(Typography, { className: styles.fontColorBlack, variant: 'h6', noWrap: true, children: selectedLang.LblApplicationTitle }), _jsx("div", { className: 'flex', children: _jsx(ShowUserAvatar, { avatarUser: loggedInUserDetails }) })] }));
};
export default ApplicationTitle;
