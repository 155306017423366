import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Admin, DepartmentManager, UserNavs } from 'components/utility/role-navigation';
import { useScreenSizeContext } from 'components/utility/screen-size-context';
import Language from 'utils/language';
import { USER_ROLE } from 'utils/user-role';
import { CHANGED_ROLE } from '../../../../utils/constants';
import { useUpdateNavigationMenu } from '../../_shared/hooks';
import * as styles from '../../navigation.module.scss';
const DrawerContent = ({ userRole, handleDrawer, isOpenDrawer }) => {
    const theme = useTheme();
    const [selectedLang] = useState(Language.jap);
    const [dynamicNavigation, setDynamicNavigation] = useState([]);
    const { isMobile } = useScreenSizeContext();
    const { updateSelectedMenu, menuSelected, handleHomeButtonClick } = useUpdateNavigationMenu();
    const changedRole = localStorage.getItem(CHANGED_ROLE);
    useEffect(() => {
        if (changedRole === null) {
            if (userRole === USER_ROLE.ADMIN) {
                setDynamicNavigation(Admin);
            }
            else if (userRole === USER_ROLE.MANAGER) {
                setDynamicNavigation(DepartmentManager);
            }
            else {
                setDynamicNavigation(UserNavs);
            }
        }
        else {
            setDynamicNavigation(UserNavs);
        }
    }, [changedRole, userRole]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.menuIconContainer, children: [_jsx(IconButton, { onClick: () => {
                            handleDrawer(false);
                        }, size: 'large', children: theme.direction === 'rtl' ? (_jsx(ChevronRightIcon, { className: styles.primaryColorCode })) : (_jsx(ChevronLeftIcon, { className: styles.primaryColorCode })) }), _jsx(Typography, { children: _jsx("img", { onClick: () => {
                                if (isMobile) {
                                    handleDrawer(false);
                                }
                                handleHomeButtonClick();
                            }, className: clsx([styles.logoImageJustify, styles.logoMainTop]), alt: selectedLang.LblApplicationTitle, src: '../../../../../../Content/custom-icons/cookai_logo_psc.png', width: 110, height: 25, loading: 'lazy', decoding: 'async' }) })] }), _jsx(List, { className: clsx([styles.menuText, styles.leftNavigationListContainer]), children: dynamicNavigation &&
                    dynamicNavigation.map((item) => (_jsxs(ListItemButton, { onClick: () => {
                            if (isMobile) {
                                handleDrawer(false);
                            }
                            updateSelectedMenu(item.navigateTo, item.navigateIndex);
                        }, className: clsx([
                            styles.menuItem,
                            {
                                [styles.selected]: menuSelected === item.navigateIndex,
                            },
                        ]), children: [_jsx(ListItemIcon, { children: React.cloneElement(item.icon, {
                                    className: styles.menuText,
                                    style: {
                                        height: 20,
                                        width: 20,
                                    },
                                }) }), _jsx(ListItemText, { sx: {
                                    height: isOpenDrawer ? undefined : 24,
                                }, primary: isOpenDrawer ? item.name : null })] }, item.key))) })] }));
};
export default DrawerContent;
