import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Language from 'utils/language';
import * as styles from './modal.module.scss';
function IxModal(props) {
    const [selectedLanguage] = useState(Language.jap);
    const { children, setTemplateToNull, handleClose, modaltitle, open, modalSubTitle, maxWidth, closeIconTitle, customOnClose, } = props;
    return (_jsxs(Dialog, { open: open, "aria-labelledby": 'customized-dialog-title', fullWidth: true, className: styles.modalStyle, maxWidth: maxWidth, children: [_jsx(MuiDialogTitle, { className: styles.modalTitleContainer, children: _jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 1.25, py: 1, children: [_jsx(Typography, { className: styles.modalTitle, children: modaltitle }), _jsxs("div", { className: styles.rightCrossIconContainer, onClick: () => {
                                if (typeof customOnClose === 'function') {
                                    customOnClose();
                                }
                                handleClose(!open);
                                if (typeof setTemplateToNull === 'function') {
                                    setTemplateToNull();
                                }
                            }, children: [_jsx(CloseIcon, { className: styles.crossIcon }), closeIconTitle === null ? null : (_jsx("div", { className: styles.closeText, children: closeIconTitle || selectedLanguage.BtnClose }))] })] }) }), modalSubTitle ? (_jsxs("div", { className: styles.subtitleContainer, children: [_jsx(InfoIcon, { className: styles.icon }), _jsx(Typography, { className: styles.subtitleText, children: modalSubTitle })] })) : null, _jsx(MuiDialogContent, { classes: {
                    root: styles.dialogContentRoot,
                }, children: children })] }));
}
export default IxModal;
