// extracted by mini-css-extract-plugin
var _1 = "_checkBoxDisable_MfE1G";
var _2 = "_columnInfoFont_s6QAJ";
var _3 = "_columnNameFont_GiMYq";
var _4 = "_editDeleteBtnContainer_OXd6w";
var _5 = "_editDeleteIconsStyle_bEBCD";
var _6 = "_editDeleteTooltipStyle_JzAxL";
var _7 = "#1fa8a0";
var _8 = "_iconContainer_k2kps";
var _9 = "_mobileTableCard_iNlX6";
var _a = "#f44336";
var _b = "_tableRowSelected__zLvR";
var _c = "_textEllipsis_Z1vhv";
export { _1 as "checkBoxDisable", _2 as "columnInfoFont", _3 as "columnNameFont", _4 as "editDeleteBtnContainer", _5 as "editDeleteIconsStyle", _6 as "editDeleteTooltipStyle", _7 as "greenColor", _8 as "iconContainer", _9 as "mobileTableCard", _a as "redColor", _b as "tableRowSelected", _c as "textEllipsis" }
