// extracted by mini-css-extract-plugin
var _1 = "_button_tV_qm";
var _2 = "_goTo_xDi4k";
var _3 = "#1fa8a0";
var _4 = "_inputPadding_TG9OU";
var _5 = "_pageInput_w3CNT";
var _6 = "_pagination_wwpUY";
var _7 = "_recPerPage_maqQK";
var _8 = "#f44336";
var _9 = "_selectPadding_Ik3oc";
var _a = "_text_tWqbu";
export { _1 as "button", _2 as "goTo", _3 as "greenColor", _4 as "inputPadding", _5 as "pageInput", _6 as "pagination", _7 as "recPerPage", _8 as "redColor", _9 as "selectPadding", _a as "text" }
