import { jsx as _jsx } from "react/jsx-runtime";
import Button, {} from '@mui/material/Button';
import clsx from 'clsx';
import * as styles from './btn-white.module.scss';
const IxBtnWhite = ({ className, ...others }) => {
    return (_jsx(Button, { className: clsx([{ [clsx(className)]: !!className }, styles.btnStandardSize, styles.mr10]), variant: 'outlined', classes: {
            outlined: styles.btnWhite,
        }, disableElevation: true, disableRipple: true, ...others }));
};
export default IxBtnWhite;
