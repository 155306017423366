import { jsx as _jsx } from "react/jsx-runtime";
import Button, {} from '@mui/material/Button';
import clsx from 'clsx';
import * as styles from './btn.module.scss';
const IxMdButton = ({ label, className, disabled = false, ...others }) => {
    return (_jsx(Button, { className: clsx([styles.btnStandardSize, className]), disabled: disabled, classes: {
            [others.variant]: styles.buttonPrimaryLight,
        }, disableRipple: true, disableElevation: true, ...others, children: label }));
};
export default IxMdButton;
