import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { useShowToast } from '_shared/hooks';
import { isImageFile } from 'utils/is-image-file';
import Language from 'utils/language';
import { canvasPreview } from './canvas-preview';
import { useDebounceEffect } from './use-debounce-effect';
import 'react-image-crop/src/ReactCrop.scss';
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(makeAspectCrop({
        unit: '%',
        width: 90,
    }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}
const ImageUpload = (props) => {
    const { getImageSrc, shouldSetToInitial } = props;
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [aspect] = useState(1);
    const [selectedLanguage] = useState(Language.jap);
    const showToast = useShowToast();
    useDebounceEffect(async () => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            getImageSrc(previewCanvasRef.current.toDataURL('image/jpeg', 1));
        }
    }, 100, [completedCrop]);
    const setToInitial = useCallback(() => {
        setImgSrc('');
        setCompletedCrop(undefined);
        getImageSrc('');
    }, [getImageSrc]);
    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const uploadedFile = e.target.files[0];
            setCrop(undefined);
            if (isImageFile(uploadedFile) && uploadedFile.size < MAX_FILE_SIZE) {
                const reader = new FileReader();
                reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
                reader.readAsDataURL(uploadedFile);
            }
            if (!isImageFile(uploadedFile)) {
                showToast('Only PNG, JPG or JPEG file is supported', 'error');
                setToInitial();
            }
            if (uploadedFile.size > MAX_FILE_SIZE) {
                showToast('File should be less than 5mb', 'error');
                setToInitial();
            }
        }
    }
    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }
    useEffect(() => {
        if (!!shouldSetToInitial) {
            setToInitial();
        }
    }, [setToInitial, shouldSetToInitial]);
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { variant: 'contained', style: {
                    color: 'white',
                }, component: 'label', children: [selectedLanguage.BrowseImage, _jsx("input", { hidden: true, accept: 'image/*', type: 'file', onChange: onSelectFile })] }), !!imgSrc ? (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                            padding: 10,
                        } }), _jsx(ReactCrop, { crop: crop, onChange: (_, percentCrop) => {
                            setCrop(percentCrop);
                        }, onComplete: (c) => setCompletedCrop(c), aspect: aspect, children: _jsx("img", { ref: imgRef, alt: 'Crop me', src: imgSrc, onLoad: onImageLoad }) })] })) : null, !!completedCrop ? (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                            padding: 10,
                        } }), _jsx("div", { children: _jsx("canvas", { ref: previewCanvasRef, style: {
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            } }) })] })) : null] }));
};
export default ImageUpload;
