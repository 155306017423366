import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LeftNavigationContainer from 'components/left-navigation/left-navigation-container';
import { TOKEN, USER_ROLE_INFO } from 'utils/constants';
import { ROUTES } from '../role-navigation';
const SecureRoute = () => {
    const token = localStorage.getItem(TOKEN);
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [canLogin, setCanLogin] = useState(true);
    useEffect(() => {
        if (localStorage.getItem(USER_ROLE_INFO)) {
            const { AdminApproved } = JSON.parse(localStorage.getItem(USER_ROLE_INFO));
            setCanLogin(AdminApproved);
        }
    }, []);
    useEffect(() => {
        if (token === null || token === '') {
            setIsLoggedIn(false);
        }
        else {
            setIsLoggedIn(true);
        }
    }, [token]);
    return (_jsx(_Fragment, { children: isLoggedIn === null ? (_jsx(_Fragment, {})) : isLoggedIn ? (canLogin ? (_jsxs(_Fragment, { children: [_jsx(LeftNavigationContainer, { "data-testid": 'navigation' }), _jsx("main", { className: 'content', id: 'main', children: _jsx(Outlet, {}) })] })) : (_jsx(Navigate, { to: ROUTES.UNAUTHORIZED, replace: true }))) : (_jsx(Navigate, { to: `${ROUTES.LOGIN}`, replace: true })) }));
};
export default SecureRoute;
