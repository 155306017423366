import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loading from 'components/loader';
import { ROUTES } from 'components/utility/role-navigation';
import { USER_ROLE_INFO } from 'utils/constants';
const RoleCheck = ({ role }) => {
    const userRoleInfo = localStorage.getItem(USER_ROLE_INFO);
    if (userRoleInfo !== null) {
        const { UserRole } = JSON.parse(userRoleInfo);
        if (UserRole === role) {
            return _jsx(Outlet, {});
        }
        else {
            return _jsx(Navigate, { to: ROUTES.HOME });
        }
    }
    return _jsx(Loading, { loading: true });
};
export default RoleCheck;
