import * as _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetDomainName, useResetPaginationState, useSplitLocationHook } from '_shared/hooks';
import { navigationArray, ROUTES } from 'components/utility/role-navigation';
import { USER_ROLE_INFO } from 'utils/constants';
import { USER_ROLE } from 'utils/user-role';
export const useUpdateNavigationMenu = () => {
    const { UserRole } = JSON.parse(localStorage.getItem(USER_ROLE_INFO));
    const navigate = useNavigate();
    const resetPagination = useResetPaginationState();
    const domain = useGetDomainName();
    const splitLocation = useSplitLocationHook();
    const [menuSelected, setMenuSelected] = useState(_.isEqual(UserRole, USER_ROLE.ADMIN) ? 1 : 2);
    useEffect(() => {
        let currentRoute = '/';
        if (splitLocation.length === 2) {
            currentRoute = (currentRoute + splitLocation[1]);
        }
        else if (splitLocation.length > 2) {
            currentRoute = (currentRoute + splitLocation[2]);
        }
        const selectedIndex = navigationArray[currentRoute];
        setMenuSelected(selectedIndex);
    }, [splitLocation]);
    const updateSelectedMenu = useCallback((redirectUrl, selectedIndex) => {
        resetPagination();
        navigate(`/${domain}${redirectUrl}`);
        setMenuSelected(selectedIndex);
    }, [domain, navigate, resetPagination]);
    const handleHomeButtonClick = useCallback(() => {
        let redirectUrl;
        if (UserRole === USER_ROLE.ADMIN) {
            redirectUrl = ROUTES.ADMIN;
        }
        else if (UserRole === USER_ROLE.MANAGER) {
            redirectUrl = ROUTES.DEPARTMENT_ADMIN;
        }
        else {
            redirectUrl = ROUTES.GENERAL_USER;
        }
        updateSelectedMenu(redirectUrl, 1);
    }, [UserRole, updateSelectedMenu]);
    return { menuSelected, updateSelectedMenu, handleHomeButtonClick };
};
