import { jsx as _jsx } from "react/jsx-runtime";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import Language from 'utils/language';
import * as styles from './ix-picker.module.scss';
const IxDatePicker = ({ onError, onClose, onChange, ...others }) => {
    const [selectedLanguage] = useState(Language.jap);
    const [hasError, setHasError] = useState(false);
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { format: 'YYYY/MM/DD', slotProps: {
                textField: {
                    helperText: hasError ? selectedLanguage.MinDateError : null,
                    InputProps: {
                        classes: {
                            input: styles.inputPadding,
                        },
                    },
                },
                desktopPaper: {
                    classes: {
                        root: styles.paperStyle,
                    },
                },
                mobilePaper: {
                    classes: {
                        root: styles.paperStyle,
                    },
                },
            }, className: styles.root, onError: (reason, value) => {
                if (reason !== null) {
                    setHasError(true);
                }
                if (typeof onError === 'function') {
                    onError(reason, value);
                }
            }, onClose: () => {
                setHasError(false);
                if (typeof onClose === 'function') {
                    onClose();
                }
            }, onChange: (value, context) => {
                if (context.validationError === null) {
                    setHasError(false);
                }
                if (typeof onChange === 'function') {
                    onChange(value, context);
                }
            }, ...others }) }));
};
export default IxDatePicker;
