import { jsx as _jsx } from "react/jsx-runtime";
import TextField, {} from '@mui/material/TextField';
import clsx from 'clsx';
import * as styles from './form-fields.module.scss';
const TextInputOutlined = (props) => {
    const { input, className, meta: { touched, error }, ...others } = props;
    return (_jsx(TextField, { ...input, variant: 'outlined', className: clsx([{ className: Boolean(className) }]), helperText: touched && error, error: touched && !!error, fullWidth: true, classes: {
            root: styles.inputRoot,
        }, InputProps: {
            classes: {
                input: clsx([{ [styles.outlinedInput]: !props.multiline }]),
            },
        }, ...others }));
};
export default TextInputOutlined;
