import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useScreenSizeContext } from 'components/utility/screen-size-context';
import { useAppDispatch } from 'store/hooks';
import { setDrawerState } from 'store/reducers/ui/drawer';
import { DRAWER_WIDTH, USER_ROLE_INFO } from 'utils/constants';
import Language from 'utils/language';
import { USER_ROLE } from 'utils/user-role';
import { useUpdateNavigationMenu } from './_shared/hooks';
import AppBar from './app-bar';
import ApplicationTitle from './application-title';
import Drawer from './drawer';
import DrawerContent from './drawer/content';
import * as styles from './navigation.module.scss';
export default function LeftNavigation() {
    const { UserRole } = JSON.parse(localStorage.getItem(USER_ROLE_INFO));
    const [selectedLang] = useState(Language.jap);
    const [userRole, setUserRole] = useState(USER_ROLE.ADMIN);
    const [open, setOpen] = useState(true);
    const [openMobile, setOpenMobile] = useState(false);
    const { screenWidth } = useScreenSizeContext();
    const dispatch = useAppDispatch();
    const { handleHomeButtonClick } = useUpdateNavigationMenu();
    const handleDrawer = useCallback((value) => {
        setOpen(value);
        setOpenMobile(value);
        dispatch(setDrawerState(value));
    }, [dispatch]);
    useEffect(() => {
        setUserRole(UserRole);
    }, [UserRole]);
    return (_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}), screenWidth && screenWidth > 600 ? (_jsxs(_Fragment, { children: [_jsx(AppBar, { position: 'fixed', open: open, children: _jsxs(Toolbar, { children: [_jsx(IconButton, { color: 'inherit', "aria-label": 'open drawer', onClick: () => {
                                        handleDrawer(true);
                                    }, edge: 'start', className: clsx(styles.menuButton, {
                                        [styles.hide]: open,
                                    }), size: 'large', children: _jsx(MenuIcon, {}) }), _jsx(ApplicationTitle, {})] }) }), _jsx(Drawer, { variant: 'permanent', open: open, children: _jsx(DrawerContent, { isOpenDrawer: open, userRole: userRole, handleDrawer: handleDrawer }) })] })) : (_jsxs(_Fragment, { children: [_jsx(MuiAppBar, { position: 'fixed', children: _jsxs(Toolbar, { children: [_jsx(IconButton, { color: 'inherit', "aria-label": 'Open drawer', onClick: () => {
                                        handleDrawer(true);
                                    }, className: styles.navButton, size: 'large', children: _jsx(MenuIcon, {}) }), _jsx("div", { className: styles.iconImgContainer, children: _jsx("img", { onClick: () => {
                                            handleHomeButtonClick();
                                        }, className: clsx([styles.logoMainTop, styles.logoImageJustify, styles.onlyLogoWidth]), alt: selectedLang.LblApplicationTitle, src: '../../../../Content/custom-icons/short-safety-icon.png' }) }), _jsx(ApplicationTitle, {})] }) }), _jsx(Box, { component: 'nav', sx: { width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }, children: _jsx(MuiDrawer, { variant: 'temporary', anchor: 'left', open: openMobile, onClose: () => {
                                handleDrawer(false);
                            }, ModalProps: {
                                keepMounted: true,
                            }, sx: {
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
                            }, children: _jsx(DrawerContent, { isOpenDrawer: openMobile, userRole: userRole, handleDrawer: handleDrawer }) }) })] })), _jsx("div", { className: styles.fakeDivToOverlapBorder })] }));
}
