import { jsx as _jsx } from "react/jsx-runtime";
import AccountTree from '@mui/icons-material/AccountTree';
import Dashboard from '@mui/icons-material/Dashboard';
import FlashAuto from '@mui/icons-material/FlashAuto';
import Layers from '@mui/icons-material/Layers';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import ListAlt from '@mui/icons-material/ListAlt';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import Language from 'utils/language';
export const ROUTES = {
    HOME: '/',
    EMPLOYEE: '/employee',
    BRANCH: '/branch',
    DEPARTMENT: '/department',
    QUESTION_ANSWER_RESULT: '/question-answer-result',
    TEMPLATE_MANAGEMENT: '/template-management',
    DELIVERY_SETTINGS: '/delivery-settings',
    RESULT: '/result',
    IMPORT_HISTORY: '/import-history',
    RESPONSE_DETAILS: '/response-details',
    SPECIFIC_RESULT: '/specific-result',
    SIGN_IN_START: '/signinstart',
    SIGN_IN_END: '/signinend',
    LOGIN: '/login',
    QUESTIONNAIRE_FEEDBACK: '/questionnaireFeedback',
    UNAUTHORIZED: '/unauthorised',
    ADMIN: '/admin',
    DEPARTMENT_ADMIN: '/department-admin',
    GENERAL_USER: '/general-user',
};
export const navigationArray = {
    [ROUTES.HOME]: 1,
    [ROUTES.ADMIN]: 1,
    [ROUTES.GENERAL_USER]: 1,
    [ROUTES.EMPLOYEE]: 2,
    [ROUTES.IMPORT_HISTORY]: 2,
    [ROUTES.BRANCH]: 3,
    [ROUTES.DEPARTMENT]: 4,
    [ROUTES.QUESTION_ANSWER_RESULT]: 5,
    [ROUTES.RESPONSE_DETAILS]: 5,
    [ROUTES.SPECIFIC_RESULT]: 5,
    [ROUTES.TEMPLATE_MANAGEMENT]: 6,
    [ROUTES.DELIVERY_SETTINGS]: 7,
    [ROUTES.RESULT]: 8,
};
export const NavDashboard = {
    name: Language.jap.NavDashboard,
    key: 'Dashboard',
    icon: _jsx(Dashboard, {}),
    navigateTo: ROUTES.HOME,
    navigateIndex: navigationArray[ROUTES.HOME],
};
export const NavEmployee = {
    name: Language.jap.NavEmployee,
    key: 'Employees',
    icon: _jsx(PeopleAlt, {}),
    navigateTo: ROUTES.EMPLOYEE,
    navigateIndex: navigationArray[ROUTES.EMPLOYEE],
};
export const NavBranch = {
    name: Language.jap.NavBranch,
    key: 'Branches',
    icon: _jsx(AccountTree, {}),
    navigateTo: ROUTES.BRANCH,
    navigateIndex: navigationArray[ROUTES.BRANCH],
};
export const NavDepartment = {
    name: Language.jap.NavDepartment,
    key: 'Departments',
    icon: _jsx(Layers, {}),
    navigateTo: ROUTES.DEPARTMENT,
    navigateIndex: navigationArray[ROUTES.DEPARTMENT],
};
export const NavResponseQuestion = {
    name: Language.jap.NavResponseQuestion,
    key: 'QuestionnaireResults',
    icon: _jsx(LibraryBooks, {}),
    navigateTo: ROUTES.QUESTION_ANSWER_RESULT,
    navigateIndex: navigationArray[ROUTES.QUESTION_ANSWER_RESULT],
};
export const NavTemplateManagement = {
    name: Language.jap.NavTemplateManagement,
    key: 'TemplateManagement',
    icon: _jsx(ListAlt, {}),
    navigateTo: ROUTES.TEMPLATE_MANAGEMENT,
    navigateIndex: navigationArray[ROUTES.TEMPLATE_MANAGEMENT],
};
export const NavDeliverySettings = {
    name: Language.jap.NavAutomaticDelivery,
    key: 'DeliverySettings',
    icon: _jsx(FlashAuto, {}),
    navigateTo: ROUTES.DELIVERY_SETTINGS,
    navigateIndex: navigationArray[ROUTES.DELIVERY_SETTINGS],
};
export const NavResult = {
    name: Language.jap.Result,
    key: 'Result',
    icon: _jsx(LibraryBooks, {}),
    navigateTo: ROUTES.RESULT,
    navigateIndex: navigationArray[ROUTES.RESULT],
};
export const DepartmentManager = [
    { ...NavEmployee, navigateTo: `${ROUTES.DEPARTMENT_ADMIN}${ROUTES.EMPLOYEE}` },
    { ...NavResponseQuestion, navigateTo: `${ROUTES.DEPARTMENT_ADMIN}${ROUTES.QUESTION_ANSWER_RESULT}` },
];
export const Admin = [
    {
        ...NavDashboard,
        navigateTo: `${ROUTES.ADMIN}`,
    },
    { ...NavEmployee, navigateTo: `${ROUTES.ADMIN}${ROUTES.EMPLOYEE}` },
    { ...NavBranch, navigateTo: `${ROUTES.ADMIN}${ROUTES.BRANCH}` },
    { ...NavDepartment, navigateTo: `${ROUTES.ADMIN}${ROUTES.DEPARTMENT}` },
    { ...NavResponseQuestion, navigateTo: `${ROUTES.ADMIN}${ROUTES.QUESTION_ANSWER_RESULT}` },
    { ...NavTemplateManagement, navigateTo: `${ROUTES.ADMIN}${ROUTES.TEMPLATE_MANAGEMENT}` },
    { ...NavDeliverySettings, navigateTo: `${ROUTES.ADMIN}${ROUTES.DELIVERY_SETTINGS}` },
];
export const UserNavs = [
    {
        name: Language.jap.NavUserDashboard,
        key: 'Dashboard',
        icon: _jsx(Dashboard, {}),
        navigateIndex: navigationArray[ROUTES.HOME],
        navigateTo: `${ROUTES.GENERAL_USER}`,
    },
    {
        ...NavResult,
        navigateTo: `${ROUTES.GENERAL_USER}${ROUTES.RESULT}`,
    },
];
