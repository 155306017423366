import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useResetMultiDeleteState, useSetDeletableRowData } from '_shared/hooks';
import NoDataMessage from 'components/NoDataMessage/no-data-message';
import { useScreenSizeContext } from 'components/utility/screen-size-context';
import { useAppSelector } from 'store/hooks';
import { selectDeletableRowData, selectIsAllDeleteChecked } from 'store/reducers/ui/multi-delete/selectors';
import CustomPagination from '../custom-pagination';
import IxMultiDeleteButton from '../multi-delete-btn/ix-multi-delete-btn';
import Desktop from './desktop';
import Mobile from './mobile';
import { selectHasApiCalledFinished } from './selectors';
function DataList(props) {
    const { data, getData, defaultSortedColumn, columns, hasCheckBox, onDeleteHandler, onEditHandler, order, rowOnClickHandler, customRowClass, multiDeleteClick, hideMultiDeleteButton = false, hidePagination = false, totalDeletable, showHorizontalScroll, getSortColumnOrder, shouldClientSideSort = true, } = props;
    const apiCalledFinished = useAppSelector(selectHasApiCalledFinished);
    const tempIsAllDeleteChecked = useAppSelector(selectIsAllDeleteChecked);
    const tempDeletableRowData = useAppSelector(selectDeletableRowData);
    const { isMobile } = useScreenSizeContext();
    const handleSetRowData = useSetDeletableRowData();
    const resetMultipleDeleteState = useResetMultiDeleteState();
    useEffect(() => {
        if (tempIsAllDeleteChecked) {
            handleSetRowData(_.uniq([...tempDeletableRowData, ...data.filter((i) => i.IsDeletable).map((item) => item.Id)]));
        }
    }, [data, handleSetRowData, tempIsAllDeleteChecked]);
    useEffect(() => {
        if (typeof getData === 'function') {
            getData(tempIsAllDeleteChecked, tempDeletableRowData);
        }
    }, [tempDeletableRowData, getData, tempIsAllDeleteChecked]);
    useEffect(() => {
        if (apiCalledFinished) {
            resetMultipleDeleteState();
        }
    }, [apiCalledFinished, resetMultipleDeleteState]);
    useEffect(() => {
        columns.map((column) => {
            if (column.actionType === 'custom') {
                if (column.customActions === undefined) {
                    throw new Error('you have to pass custom action  for custom action type in the column');
                }
            }
            if (column.actionType === 'default') {
                if (typeof onEditHandler !== 'function' || typeof onDeleteHandler !== 'function') {
                    throw new Error('you have to pass on delete and on edit handler for default action type');
                }
            }
            if (column.id === 'Checkbox') {
                if (typeof multiDeleteClick !== 'function') {
                    throw new Error('you have to pass multi delete handler');
                }
            }
            return column;
        });
    }, [columns, multiDeleteClick, onDeleteHandler, onEditHandler]);
    return (_jsx(_Fragment, { children: _jsxs("div", { style: { width: '100%', display: showHorizontalScroll ? 'grid' : undefined }, children: [isMobile ? (_jsx(_Fragment, { children: data.length > 0 ? (_jsx(Mobile, { columns: columns, defaultSortedColumn: defaultSortedColumn, data: data, hasCheckBox: hasCheckBox, onDeleteHandler: onDeleteHandler, onEditHandler: onEditHandler, multiDeleteClick: multiDeleteClick, order: order, rowOnClickHandler: rowOnClickHandler, customRowClass: customRowClass, totalCount: totalDeletable, shouldClientSideSort: shouldClientSideSort })) : (_jsx(NoDataMessage, {})) })) : (_jsxs(_Fragment, { children: [hideMultiDeleteButton ? null : (_jsx(IxMultiDeleteButton, { deleteCount: tempDeletableRowData.length, onClick: () => {
                                if (multiDeleteClick) {
                                    multiDeleteClick();
                                }
                            }, totalCount: totalDeletable, isAllDeleteChecked: tempIsAllDeleteChecked })), _jsx(Paper, { className: 'scroll-paper-style', id: 'scroll', elevation: 0, children: _jsx(Desktop, { columns: columns, defaultSortedColumn: defaultSortedColumn, data: data, hasCheckBox: hasCheckBox, onDeleteHandler: onDeleteHandler, onEditHandler: onEditHandler, order: order, rowOnClickHandler: rowOnClickHandler, customRowClass: customRowClass, getSortColumnOrder: getSortColumnOrder, shouldClientSideSort: shouldClientSideSort }) })] })), hidePagination ? null : (_jsx("div", { style: {
                        backgroundColor: 'white',
                    }, children: _jsx(CustomPagination, {}) }))] }) }));
}
export default DataList;
