import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
const LightTooltip = styled(({ className, ...props }) => (_jsx(Tooltip, { ...props, classes: { popper: className }, children: props.children })))(({ theme, maxwidth, padding }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        maxWidth: maxwidth || 350,
        padding: padding || 20,
        fontSize: 11,
        border: '1px solid #d4d4d4',
    },
}));
const IxTooltip = (props) => {
    const { children, title, placement, padding, maxwidth } = props;
    return (_jsx(_Fragment, { children: _jsx(LightTooltip, { title: title, placement: placement || 'top', enterTouchDelay: 0, padding: padding, maxwidth: maxwidth, children: children }) }));
};
export default IxTooltip;
