import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import IxSnackBar from 'components/composite/ix-snackbar';
import { useAppSelector } from 'store/hooks';
import { selectToastMessage, selectToastOpen, selectToastSeverity } from 'store/reducers/ui/toast/selectors';
const Toast = () => {
    const open = useAppSelector(selectToastOpen);
    const message = useAppSelector(selectToastMessage);
    const severity = useAppSelector(selectToastSeverity);
    return _jsx(_Fragment, { children: open && message && severity ? _jsx(IxSnackBar, { open: open, severity: severity, message: message }) : null });
};
export default React.memo(Toast);
