import { jsx as _jsx } from "react/jsx-runtime";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import IxBtnWhite from 'components/basic/ix-btn-white';
import Language from 'utils/language';
const ExportCsv = ({ csvData, fileName, disabled, onClick }) => {
    const [selectedLang] = useState(Language.jap);
    return (_jsx(CSVLink, { filename: fileName, className: 'no-decoration', data: csvData, onClick: () => {
            if (typeof onClick === 'function') {
                onClick();
            }
            return !disabled;
        }, children: _jsx(IxBtnWhite, { type: 'submit', startIcon: _jsx(GetAppIcon, { style: { marginLeft: '10px' } }), disabled: disabled, children: selectedLang.ExportCSV }) }));
};
export default ExportCsv;
