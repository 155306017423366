// extracted by mini-css-extract-plugin
var _1 = "_closeText_ths0M";
var _2 = "_crossIcon_eXp13";
var _3 = "_dialogContentRoot_flXHQ";
var _4 = "#1fa8a0";
var _5 = "_icon_BFow2";
var _6 = "_modalStyle_pWPq3";
var _7 = "_modalTitle_doenI";
var _8 = "_modalTitleContainer_KAd0z";
var _9 = "#f44336";
var _a = "_rightCrossIconContainer_X92do";
var _b = "_subtitleContainer_w3uGD";
var _c = "_subtitleText_aGiCm";
export { _1 as "closeText", _2 as "crossIcon", _3 as "dialogContentRoot", _4 as "greenColor", _5 as "icon", _6 as "modalStyle", _7 as "modalTitle", _8 as "modalTitleContainer", _9 as "redColor", _a as "rightCrossIconContainer", _b as "subtitleContainer", _c as "subtitleText" }
