import { useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setRecordPerPage, setCurrentPageValue } from 'store/reducers/ui/pagination';
import { selectShouldShowZeroPage, selectCurrentPage, selectRowPerPage } from 'store/reducers/ui/pagination/selectors';
import { selectTotalPage } from './selectors';
export const usePaginationSelectors = () => {
    return useAppSelector((state) => {
        return {
            currentPage: selectCurrentPage(state),
            rowPerPage: selectRowPerPage(state),
            totalPage: selectTotalPage(state),
            shouldShowZeroPage: selectShouldShowZeroPage(state),
        };
    });
};
export const useSetRecordPerPage = () => {
    const dispatch = useAppDispatch();
    return useCallback((value) => {
        dispatch(setRecordPerPage(value));
    }, [dispatch]);
};
export const useSetCurrentPage = () => {
    const dispatch = useAppDispatch();
    return useCallback((value) => {
        dispatch(setCurrentPageValue(value));
    }, [dispatch]);
};
