import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import IxBtnWhite from 'components/basic/ix-btn-white';
import IxMdButton from 'components/basic/ix-md-button';
import IxModal from 'components/composite/ix-modal';
import ImageUpload from 'components/image-upload';
import { useLazyGetImageFromGraphApiQuery } from 'store/rtk-query/services/user';
import { TOKEN, USER_ROLE_INFO } from 'utils/constants';
import Language from 'utils/language';
import { useRemoveProfilePicture, useUploadProfilePicture } from './hooks';
import * as styles from './avatar.module.scss';
const ProfileUpload = (props) => {
    let userPrincipalName;
    const { openModal, setOpenModal } = props;
    const userRoleInfo = localStorage.getItem(USER_ROLE_INFO);
    const { isO365 } = JSON.parse(localStorage.getItem(TOKEN));
    if (userRoleInfo !== null) {
        const { UserPrincipalName } = JSON.parse(userRoleInfo);
        userPrincipalName = UserPrincipalName;
    }
    const [uploadedImage, setUploadedImage] = useState(null);
    const [previewGraphImage, setPreviewGraphImage] = useState(false);
    const [selectedLanguage] = useState(Language.jap);
    const uploadProfilePicture = useUploadProfilePicture();
    const removeProfilePicture = useRemoveProfilePicture();
    const [getImage, { data }] = useLazyGetImageFromGraphApiQuery();
    useEffect(() => {
        if (previewGraphImage && !!data) {
            setUploadedImage(data);
        }
    }, [data, previewGraphImage]);
    return (_jsxs(IxModal, { open: openModal, maxWidth: 'sm', handleClose: setOpenModal, modaltitle: selectedLanguage.UploadProfile, children: [_jsxs("div", { className: 'modal-content-padding', children: [_jsx(ImageUpload, { getImageSrc: (imgSrc) => {
                            if (!!imgSrc) {
                                setUploadedImage(imgSrc);
                                setPreviewGraphImage(false);
                            }
                            else {
                                setUploadedImage(null);
                            }
                        }, shouldSetToInitial: previewGraphImage }), _jsxs("div", { style: {
                            paddingTop: 15,
                        }, children: [!!isO365 ? (_jsx("div", { children: _jsx(Button, { variant: 'contained', onClick: () => {
                                        getImage(userPrincipalName);
                                        setPreviewGraphImage(true);
                                    }, style: {
                                        color: 'white',
                                    }, children: "Microsoft 365\u306E\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u753B\u50CF\u3092\u4F7F\u7528\u3059\u308B" }) })) : null, previewGraphImage && !!uploadedImage ? (_jsx("div", { className: styles.graphImagePreview, children: _jsx("img", { src: `data:jpeg;base64,${uploadedImage}` }) })) : null] }), _jsx("div", { style: {
                            paddingTop: 15,
                        }, children: _jsx(IxBtnWhite, { onClick: () => {
                                removeProfilePicture();
                                setOpenModal(false);
                                setPreviewGraphImage(false);
                            }, children: "\u73FE\u5728\u306E\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u753B\u50CF\u3092\u524A\u9664\u3059\u308B" }) })] }), _jsx("div", { className: 'button-container', children: _jsx(IxMdButton, { variant: 'contained', label: selectedLanguage.Save, disabled: !Boolean(uploadedImage), onClick: () => {
                        if (!!uploadedImage) {
                            uploadProfilePicture({
                                fileString: uploadedImage,
                                isLocalImage: !previewGraphImage,
                            });
                        }
                        setOpenModal(false);
                        setPreviewGraphImage(false);
                    } }) })] }));
};
export default ProfileUpload;
