import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useState } from 'react';
import IxMultiDeleteButton from 'components/composite/multi-delete-btn/ix-multi-delete-btn';
import { useAppSelector } from 'store/hooks';
import { selectDeletableRowData, selectIsAllDeleteChecked } from 'store/reducers/ui/multi-delete/selectors';
import Language from 'utils/language';
import AllCheck from '../../_shared/all-check';
import * as styles from './mobileTopSection.module.scss';
function MobileTableTopSection(props) {
    const { data, multiDeleteClick, totalCount, hasVerticalScroll } = props;
    const [selectedLanguage] = useState(Language.jap);
    const tempIsAllDeleteChecked = useAppSelector(selectIsAllDeleteChecked);
    const tempDeletableRowData = useAppSelector(selectDeletableRowData);
    return (_jsxs("div", { className: clsx([styles.mobileTopSection]), children: [_jsx("div", { children: _jsx(IxMultiDeleteButton, { deleteCount: tempDeletableRowData.length, onClick: () => {
                        if (multiDeleteClick) {
                            multiDeleteClick();
                        }
                    }, totalCount: totalCount, isAllDeleteChecked: tempIsAllDeleteChecked }) }), _jsx("div", { children: _jsxs("div", { className: clsx([
                        styles.selectCheckboxContainer,
                        {
                            [styles.scrollPaddingRight]: hasVerticalScroll,
                        },
                    ]), children: [_jsx("div", { children: selectedLanguage.SelectAll }), _jsx(AllCheck, { data: data })] }) })] }));
}
export default MobileTableTopSection;
