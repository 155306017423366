import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Outlet, Navigate } from 'react-router-dom';
import Loading from 'components/loader';
import { ROUTES } from '../role-navigation';
import { useCheckIfDomainEmpty, useDomainNavigation } from './hooks';
import InvalidDomain from './invalid-domain/invalid-domain';
const DomainCheck = () => {
    const { isDomainValid, isApiRunning } = useDomainNavigation();
    const { isDomainEmpty } = useCheckIfDomainEmpty();
    return (_jsx(_Fragment, { children: isApiRunning ? (_jsx(Loading, { loading: true })) : isDomainValid ? (_jsx(Outlet, {})) : isDomainEmpty ? (_jsx(Navigate, { to: ROUTES.LOGIN, replace: true })) : (_jsx(InvalidDomain, { message: 'Domain is not authorized' })) }));
};
export default DomainCheck;
