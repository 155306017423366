import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
const PandemicDetails = (props) => {
    const { additionalContent, templateBody } = props;
    return (_jsx(_Fragment, { children: additionalContent === null ? (_jsx("div", { style: {
                whiteSpace: 'pre-wrap',
                color: '#333333',
            }, children: templateBody })) : (_jsxs("div", { children: [_jsx("div", { style: {
                        color: '#333333',
                    }, children: additionalContent }), _jsx("div", { style: {
                        whiteSpace: 'pre-wrap',
                        color: '#333333',
                    }, children: templateBody })] })) }));
};
export default PandemicDetails;
