import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Box } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as styles from './unauthorized.module.scss';
const UnAuthorized = () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.body.classList.add(styles.bodySurvey);
        return function cleanup() {
            document.body.classList.remove(styles.bodySurvey);
        };
    }, []);
    return (_jsxs("div", { className: styles.container, children: [_jsx("div", { className: styles.topPart, children: _jsxs(Box, { display: 'flex', children: [_jsx("div", { className: styles.topLeft, children: _jsx("img", { className: clsx([styles.logoMainTop, styles.logoImageJustify]), alt: 'Cookai', src: '../../../../../Content/custom-icons/cookai_logo_psc.png' }) }), _jsx("div", { className: styles.applicationNameContainer, children: _jsx("div", { className: styles.name, children: _jsx(Typography, { variant: 'h6', noWrap: true, children: "Coo Kai \u5B89\u5426\u78BA\u8A8D" }) }) })] }) }), _jsx(Box, { display: 'flex', justifyContent: 'center', children: _jsxs(Box, { p: 2.5, color: '#297fca', children: [_jsx(Box, { textAlign: 'center', children: _jsx("img", { className: 'lock-image', alt: 'Cookai', src: '../../../../../Content/custom-icons/lock.png' }) }), _jsxs(Box, { textAlign: 'center', children: [_jsx("h3", { children: "\u30ED\u30B0\u30A4\u30F3\u3059\u308B\u6A29\u9650\u304C\u3042\u308A\u307E\u305B\u3093" }), _jsxs("h4", { children: ["\u7D9A\u884C\u3059\u308B\u306B\u306F\u5225\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u3067", _jsx("span", { style: { color: 'red', cursor: 'pointer' }, onClick: () => {
                                                localStorage.clear();
                                                navigate('/login', { replace: true });
                                            }, children: "\u30ED\u30B0\u30A4\u30F3" }), ' ', "\u3057\u3066\u304F\u3060\u3055\u3044"] })] })] }) })] }));
};
export default UnAuthorized;
