import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Language from 'utils/language';
import * as styles from './multiDeleteBtn.module.scss';
const IxMultiDeleteButton = (props) => {
    const [selectedLanguage] = useState(Language.jap);
    const { deleteCount, totalCount, onClick, isAllDeleteChecked } = props;
    return (_jsx("div", { className: styles.multiDeleteContainer, children: deleteCount > 0 ? (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'contained', startIcon: _jsx(DeleteOutlinedIcon, { fontSize: 'small' }), onClick: onClick, classes: {
                        contained: styles.multiDeleteBtn,
                    }, children: _jsx("span", { className: styles.btnLabel, children: selectedLanguage.BtnDelete }) }), _jsxs("div", { className: styles.textStyle, children: [_jsxs("b", { children: [isAllDeleteChecked ? totalCount : deleteCount, "\u00A0"] }), " ", selectedLanguage.itemDeleteMsg] })] })) : (_jsx("div", { style: { height: 30 } })) }));
};
export default IxMultiDeleteButton;
